import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import PartnerData from '../containers/PartnerData'
import { useData } from '../context'

const PartnerDetailsPage = ({ history, redirect, t }) => {
  const { data, setData } = useData()
  const [occupations, setOccupations] = useState([])

  const handleSubmit = newRegisterData => {
    newRegisterData.partnerName = `${newRegisterData.partnerFirstName} ${newRegisterData.partnerLastName}`

    window.dataLayer.push({
      birth_date: newRegisterData.birthDate,
      phone: newRegisterData.phone,
    })

    setData({
      ...data,
      ...newRegisterData,
    })

    redirect('/endereco-do-representante')
  }

  const isProd = process.env.REACT_APP_API_ENVIRONMENT === 'production'

  useEffect(() => {
    ;(async () => {
      const { data: ocuppationData } = await axios.get(
        isProd
          ? 'https://api.mundipagg.com/bigbang/v1/occupation'
          : 'https://stgapi.mundipagg.com/bigbang/v1/occupation',
      )

      const ocuppationsTitles = ocuppationData.map(occupation => ({
        label: occupation.title,
        value: occupation.title,
      }))

      setOccupations(ocuppationsTitles)
    })()
  }, [isProd])

  return (
    <PartnerData
      occupations={occupations}
      onPreviousButton={() => history.goBack()}
      onSubmit={handleSubmit}
      registerData={data}
      t={t}
    />
  )
}

PartnerDetailsPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default PartnerDetailsPage
