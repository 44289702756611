/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import pardot from '../vendor/pardot'
import { getIP } from '../vendor/helpers/getIP'
import { sendErrorEvent } from '../vendor/helpers/events'

const DataContext = React.createContext({})

const getUtmFromCookies = cookies => {
  const utmParams = ['utm_medium', 'utm_campaign', 'utm_source']
  const organicLead = {
    utm_campaign: 'autocred',
    utm_medium: 'site',
    utm_source: 'organico',
  }

  return utmParams.reduce((acc, param) => {
    if (cookies[param]) {
      acc[param] = cookies[param]
    }

    return acc
  }, organicLead)
}

const Provider = ({ children }) => {
  const [data, setData] = useState({})
  const [utmParams, setUtmParams] = useState()
  const [ip, setIp] = useState()
  const [cookies] = useCookies()

  useEffect(() => {
    const getUserIP = async () => {
      const userIp = await getIP()
      setIp(userIp)
    }

    if (!ip) getUserIP()
  }, [ip])

  useEffect(() => {
    setUtmParams(getUtmFromCookies(cookies))
  }, [cookies])

  const gaParams = {
    sessionId:
      cookies?._ga_D3WKQ4C3N8?.substring(6).split('.')[0] ||
      'not_accept_cookies_yet',
    userId: cookies?._ga?.substring(6) || 'not_accept_cookies_yet',
  }

  useEffect(() => {
    ;(async () => {
      try {
        delete data?.step

        if (Object.keys(data).length) {
          await pardot.sendFormData({
            ...gaParams,
            ...data,
          })
        }
      } catch (error) {
        sendErrorEvent(
          `autocred - ${data?.step || ''} - send pardot error`,
          error,
        )

        /* eslint-disable no-console */
        console.error(error)
      }
    })()
  }, [data, gaParams])

  const facebookParams = {
    fbc: cookies?._fbc || '',
    fbp: cookies?._fbp || '',
  }

  return (
    <DataContext.Provider
      value={{
        data,
        facebookParams,
        gaParams,
        ip,
        setData,
        utmParams,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export const useData = () => useContext(DataContext)

export const DataProvider = withRouter(Provider)
