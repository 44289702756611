/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import CreateAccount from '../containers/CreateAccount'
import { useData } from '../context'

const WelcomePage = ({ redirect, t }) => {
  const { data, gaParams, setData, utmParams } = useData()

  useEffect(() => {
    FullStoryAPI('setUserVars', { token_str: data.companyTemplateToken })
  }, [data.companyTemplateToken])

  const handleSubmit = newRegisterData => {
    FullStoryAPI('setUserVars', {
      email: newRegisterData.email,
    })

    const { isPartner } = newRegisterData

    newRegisterData.adminName = `${newRegisterData.first_name} ${newRegisterData.last_name}`

    window.dataLayer.push({
      email: newRegisterData.email,
      first_name: newRegisterData.first_name,
      last_name: newRegisterData.last_name,
    })

    setData({
      ...data,
      ...newRegisterData,
      ...utmParams,
      ...gaParams,
      partnerName: isPartner === 'Sim' ? newRegisterData.adminName : undefined,
    })

    redirect('/dados-da-empresa')
  }

  return <CreateAccount onSubmit={handleSubmit} registerData={data} t={t} />
}

WelcomePage.propTypes = {
  redirect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default WelcomePage
